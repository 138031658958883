import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a>
    <h2>
Prestige Classes
    </h2><a id="arcane-archer"></a>
    <h3>
ARCANE ARCHER
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">
      <a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG176_WEB.jpg">
        <img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit
Die</b>: d8.
    </p><a id="arcane-archer-requirements"></a>
    <h6>
Requirements
    </h6>
    <p className="initial">
      <p>{`To qualify to become an arcane archer, a character must fulfill
all the following criteria.`}</p>
    </p>
    <p>
      <b>Race</b>: Elf or half-elf.
    </p>
    <p>
      <b>Base Attack Bonus</b>: +6.
    </p>
    <p>
      <b>Feats</b>: Point Blank Shot, Precise Shot, Weapon Focus
(longbow or shortbow).
    </p>
    <p>
      <b>Spells</b>: Ability to cast 1st-level arcane spells.
    </p>
    <h6>
Class Skills
    </h6>
    <p className="initial">
      <p>{`The arcane archer�s class skills (and the key ability for each
skill) are `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">{`Craft`}</a>{` (Int), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#hide">{`Hide`}</a>{`
(Dex). `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#listen">{`Listen`}</a>{` (Wis), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#move-silently">{`Move Silently`}</a>{` (Dex), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#ride">{`Ride`}</a></p>
      <p>{`(Dex), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#spot">{`Spot`}</a>{` (Wis), `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#survival">{`Survival`}</a>{` (Wis), and `}<a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#use-rope">{`Use
Rope`}</a>{` (Dex).`}</p>
    </p>
    <p>
      <b>Skill Points at Each Level</b>: 4 + Int modifier.
    </p>
    <p>
      <b><a id="table-the-arcane-archer"></a>Table: The Arcane
Archer</b>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "8%"
          }}>
Level
          </th>
          <th style={{
            "width": "13%"
          }}>
Base
            <p>{`Attack Bonus`}</p>
          </th>
          <th style={{
            "width": "8%"
          }}>
Fort
            <p>{`Save`}</p>
          </th>
          <th style={{
            "width": "8%"
          }}>
            <p>{`Ref`}</p>
            <p>{`Save`}</p>
          </th>
          <th style={{
            "width": "8%"
          }}>
Will
            <p>{`Save`}</p>
          </th>
          <th>
Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>
1st
          </td>
          <td>
+1
          </td>
          <td>
+2
          </td>
          <td>
+2
          </td>
          <td>
+0
          </td>
          <td>
Enhance arrow +1
          </td>
        </tr>
        <tr>
          <td>
2nd
          </td>
          <td>
+2
          </td>
          <td>
+3
          </td>
          <td>
+3
          </td>
          <td>
+0
          </td>
          <td>
Imbue arrow
          </td>
        </tr>
        <tr className="odd-row">
          <td>
3rd
          </td>
          <td>
+3
          </td>
          <td>
+3
          </td>
          <td>
+3
          </td>
          <td>
+1
          </td>
          <td>
Enhance arrow +2
          </td>
        </tr>
        <tr>
          <td>
4th
          </td>
          <td>
+4
          </td>
          <td>
+4
          </td>
          <td>
+4
          </td>
          <td>
+1
          </td>
          <td>
Seeker arrow
          </td>
        </tr>
        <tr className="odd-row">
          <td>
5th
          </td>
          <td>
+5
          </td>
          <td>
+4
          </td>
          <td>
+4
          </td>
          <td>
+1
          </td>
          <td>
Enhance arrow +3
          </td>
        </tr>
        <tr>
          <td>
6th
          </td>
          <td>
+6
          </td>
          <td>
+5
          </td>
          <td>
+5
          </td>
          <td>
+2
          </td>
          <td>
Phase arrow
          </td>
        </tr>
        <tr className="odd-row">
          <td>
7th
          </td>
          <td>
+7
          </td>
          <td>
+5
          </td>
          <td>
+5
          </td>
          <td>
+2
          </td>
          <td>
Enhance arrow +4
          </td>
        </tr>
        <tr>
          <td>
8th
          </td>
          <td>
+8
          </td>
          <td>
+6
          </td>
          <td>
+6
          </td>
          <td>
+2
          </td>
          <td>
Hail of arrows
          </td>
        </tr>
        <tr className="odd-row">
          <td>
9th
          </td>
          <td>
+9
          </td>
          <td>
+6
          </td>
          <td>
+6
          </td>
          <td>
+3
          </td>
          <td>
Enhance arrow +5
          </td>
        </tr>
        <tr>
          <td className="last-row">
10th
          </td>
          <td className="last-row">
+10
          </td>
          <td className="last-row">
+7
          </td>
          <td className="last-row">
+7
          </td>
          <td className="last-row">
+3
          </td>
          <td className="last-row">
Arrow of death
          </td>
        </tr>
      </tbody>
    </table>
    <a id="arcane-archer-class-features"></a>
    <h6>
Class Features
    </h6>
    <p className="initial">
All of the following are Class Features of the arcane archer
prestige class.
    </p>
    <p>
      <b>Weapon and Armor Proficiency</b>: An arcane archer is
proficient with all simple and martial weapons, light armor,
medium armor, and shields.
    </p><a id="arcane-archer-enhance-arrow"></a>
    <p>
      <b><i>Enhance Arrow (Su)</i></b>: At 1st level, every nonmagical
arrow an arcane archer nocks and lets fly becomes magical, gaining
a +1 enhancement bonus. Unlike magic weapons created by normal
means, the archer need not spend experience points or gold pieces
to accomplish this task. However, an archer&#8217;s magic arrows only
function for her. For every two levels the character advances past
1st level in the prestige class, the magic arrows she creates gain
+1 greater potency (+1 at 1st level, +2 at 3rd level, +3 at 5th
level, +4 at 7th level, and +5 at 9th level).
    </p><a id="arcane-archer-imbue-arrow"></a>
    <p>
      <b><i>Imbue Arrow (Sp)</i></b>: At 2nd level, an arcane archer
gains the ability to place an area spell upon an arrow. When the
arrow is fired, the spell&#8217;s area is centered on where the arrow
lands, even if the spell could normally be centered only on the
caster. This ability allows the archer to use the bow&#8217;s range
rather than the spell&#8217;s range. It takes a standard action to cast
the spell and fire the arrow. The arrow must be fired in the round
the spell is cast, or the spell is wasted.
    </p><a id="arcane-archer-seeker-arrow"></a>
    <p>
      <b><i>Seeker Arrow (Sp)</i></b>: At 4th level, an arcane archer
can launch an arrow once per day at a target known to her within
range, and the arrow travels to the target, even around corners.
Only an unavoidable obstacle or the limit of the arrow&#8217;s range
prevents the arrow&#8217;s flight. This ability negates cover and
concealment modifiers, but otherwise the attack is rolled
normally. Using this ability is a standard action (and shooting
the arrow is part of the action).
    </p><a id="arcane-archer-phase-arrow"></a>
    <p>
      <b><i>Phase Arrow (Sp)</i></b>: At 6th level, an arcane archer can
launch an arrow once per day at a target known to her within
range, and the arrow travels to the target in a straight path,
passing through any nonmagical barrier or wall in its way. (Any
magical barrier stops the arrow.) This ability negates cover,
concealment, and even armor modifiers, but otherwise the attack is
rolled normally.
    </p>
    <p>
Using this ability is a standard action (and shooting the arrow is
part of the action).
    </p><a id="arcane-archer-hail-of-arrows"></a>
    <p>
      <b><i>Hail of Arrows (Sp)</i></b>: In lieu of her regular attacks,
once per day an arcane archer of 8th level or higher can fire an
arrow at each and every target within range, to a maximum of one
target for every arcane archer level she has earned. Each attack
uses the archer&#8217;s primary attack bonus, and each enemy may only be
targeted by a single arrow.
    </p><a id="arcane-archer-arrow-of-death"></a>
    <p>
      <b><i>Arrow of Death (Sp)</i></b>: At 10th level, an arcane archer
can create an arrow of death that forces the target, if damaged by
the arrow&#8217;s attack, to make a DC 20 Fortitude save or be slain
immediately. It takes one day to make an arrow of death, and the
arrow only functions for the arcane archer who created it. The
arrow of death lasts no longer than one year, and the archer can
only have one such arrow in existence at a time.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      